<template>
  <div class="markup-tables flex">
    <va-card class="flex mb-4" v-if="identifier">
      <va-card-title style="font-size: 1.5rem;" v-if="id === 'add'">New Identifier</va-card-title>
      <va-card-title style="font-size: 1.5rem;" v-else>{{ oldName }}</va-card-title>
      <va-card-content>
        <div class="table-wrapper">
          <form @submit.prevent="onsubmit">
            <va-input
              class="mb-3"
              v-model="identifier.name"
              type="text"
              label="Name"
              :error="!!nameErrors.length"
              :error-messages="nameErrors"
            />
            <div class="d-flex justify--center mt-3">
              <va-button @click="save" class="my-0">Save</va-button>
            </div>
          </form>
        </div>
      </va-card-content>
    </va-card>
    <va-card class="flex mb-4" v-if="identifier && id !== 'add'">
      <va-card-title style="font-size: 1.5rem;">Shared with</va-card-title>
      <va-card-content>
        <div style="margin-bottom: 1rem;">
          <span class="addLink" @click="addModalShow = true">+ Assign to a user</span>
        </div>
        <table>
          <tr v-for="(identifier, i) in identifiers">
            <td width="1rem" style="padding-right: .5rem;">
              {{ i + 1 }}.
            </td>
            <td>
              {{ identifier.username }}
            </td>
            <td width="1rem" style="padding-left: 1rem;">
              <va-icon :size="'1rem'" :name="'fa4-trash'" style="padding-right: 1rem; cursor: pointer; color: red;" @click="removeModal(identifier.user_id, identifier.username)" />
            </td>
          </tr>
        </table>
      </va-card-content>
    </va-card>

    <va-modal
      v-model="removeModalShow"
      hide-default-actions
      overlay-opacity="0.2"
      size="large"
    >
      <template #header>
        <h2>Remove assigned user?</h2>
      </template>
      <slot>
        <div>{{ remove_name }}</div>
      </slot>
      <template #footer>
        <va-button class="mr-2 mb-2" color="gray" @click="removeModalShow = false">Cancel</va-button>
        <va-button class="mr-2 mb-2" color="danger" @click="removeAssignment">Delete</va-button>
      </template>
    </va-modal>

    <va-modal
      v-model="addModalShow"
      hide-default-actions
      overlay-opacity="0.2"
      size="large"
    >
      <template #header>
        <h2>Assign a user</h2>
      </template>
      <slot>
        <div>
          <va-select
            class="flex"
            :options="usersSelect"
            v-model="new_user_name"
            label="Users"
            placeholder="Select User"
            :clearable="false"
          />
        </div>
      </slot>
      <template #footer>
        <va-button class="mr-2 mb-2" color="gray" @click="addModalShow = false">Cancel</va-button>
        <va-button class="mr-2 mb-2" color="primary" @click="addAssignment">Assign</va-button>
      </template>
    </va-modal>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  mounted: async function () {
    if (this.id !== 'add') {
      this.loadData()
    } else {
      this.identifier = {
        name: ''
      }
    }
  },
  data () {
    return {
      loader: null,
      token: this.$store.state.token,
      identifier: null,
      identifiers: [],
      users: [],
      usersSelect: [],
      nameErrors: [],
      id: this.$route.params.id,
      oldName: null,
      new_user_id: null,
      new_user_name: null,
      remove_id: null,
      remove_name: null,
      removeModalShow: false,
      addModalShow: false,
      creator_id: null
    }
  },
  methods: {
    loadData: async function () {
      this.loader = this.$loading.show()

      this.identifiers = []
      this.users = []
      this.usersSelect = []
      try {
        const headers = {
          Authorization: `Bearer ${this.token}`
        }
        // Load a identifier
        const identifier = await axios.get(`${process.env.VUE_APP_API_URL}/api/identifiers/${this.id}`, { headers })
        this.identifier = identifier.data.identifier
        this.oldName = this.identifier.name

        const identifiers = await axios.get(`${process.env.VUE_APP_API_URL}/api/identifiers/${this.id}/assign`, { headers })

        const users = await axios.get(`${process.env.VUE_APP_API_URL}/api/users?per_page=0`, { headers })
        this.users = users.data.users

        await Promise.all(identifiers.data.identifiers.map((identifier) => {
          if (!identifier.creator) {
            this.users.forEach((user) => {
              if (user.uid === identifier.user_id) {
                identifier.username = user.username
              }
            })
            this.identifiers.push(identifier)
          } else {
            this.creator_id = identifier.user_id
          }
        }))

        this.users.forEach((user) => {
          if (this.creator_id !== user.uid) {
            let flag = false
            this.identifiers.forEach((identifier) => {
              if (identifier.user_id === user.uid) {
                flag = true
              }
            })
            // Not already assigned
            if (!flag) {
              this.usersSelect.push(user.username)
            }
          }
        })
      } catch (error) {
        this.$router.push({ name: 'login', query: { redirect: `/pages/identifiers/${this.id}/manage` } })
      }
      this.loader.hide()
    },
    save: async function () {
      this.loader = this.$loading.show()
      try {
        // Handle errors
        this.nameErrors = []
        let error = false

        if (!this.identifier.name || this.identifier.name.length <= 0) {
          this.emailErrors.push('Enter valid Name')
          error = true
        }
        // Check errors first
        if (!error) {
          const headers = {
            Authorization: `Bearer ${this.token}`
          }
          if (this.id === 'add') {
            const payload = {
              name: this.identifier.name
            }
            // Add new identifier
            await axios.post(`${process.env.VUE_APP_API_URL}/api/identifiers`, payload, { headers })
            this.$router.push({ name: 'identifiers' })
          } else {
            if (this.oldName !== this.identifier.name) {
              const payload = {
                name: this.identifier.name
              }
              // Update identifier
              const identifier = await axios.put(`${process.env.VUE_APP_API_URL}/api/identifiers/${this.id}`, payload, { headers })
              this.identifier = identifier.data.identifier

              // Show success message
              this.$vaToast.init({
                message: 'Identifier saved successfully!',
                iconClass: 'fa-star-o',
                position: 'bottom-right',
                duration: 6000,
                fullWidth: false,
                color: 'primary',
              })

              this.oldName = this.identifier.name
            }
          }
        } else {
          // Show error message
          this.$vaToast.init({
            message: 'Please check the errors.',
            iconClass: 'fa-star-o',
            position: 'bottom-right',
            duration: 6000,
            fullWidth: false,
            color: 'danger',
          })
        }
      } catch (error) {
        if (error.response.data.message === 'Duplicate Identifier') {
          // Show error message
          this.$vaToast.init({
            message: 'Duplicate Identifier.',
            iconClass: 'fa-star-o',
            position: 'bottom-right',
            duration: 6000,
            fullWidth: false,
            color: 'danger',
          })
        } else {
          this.$router.push({ name: 'login', query: { redirect: `/pages/identifiers/${this.id}/manage` } })
        }
      }
      this.loader.hide()
    },
    removeModal (id, name) {
      this.remove_id = id
      this.remove_name = name
      this.removeModalShow = true
    },
    addAssignment: async function () {
      try {
        const headers = {
          Authorization: `Bearer ${this.token}`
        }

        this.users.forEach((user) => {
          if (user.username === this.new_user_name) {
            this.new_user_id = user.uid
          }
        })

        const payload = {
          user_id: this.new_user_id
        }
        // Load a identifier
        await axios.post(`${process.env.VUE_APP_API_URL}/api/identifiers/${this.id}/assign`, payload, { headers })

        // Show success message
        this.$vaToast.init({
          message: 'Identifier assigned successfully!',
          iconClass: 'fa-star-o',
          position: 'bottom-right',
          duration: 6000,
          fullWidth: false,
          color: 'primary',
        })

        this.new_user_id = null
        this.new_user_name = null
        this.addModalShow = false

        this.loadData()
      } catch (error) {
        this.$router.push({ name: 'login', query: { redirect: `/pages/identifiers/${this.id}/manage` } })
      }
    },
    removeAssignment: async function () {
      try {
        const headers = {
          Authorization: `Bearer ${this.token}`
        }
        // Load a identifier
        await axios.delete(`${process.env.VUE_APP_API_URL}/api/identifiers/${this.id}/assign/${this.remove_id}`, { headers })

        // Show success message
        this.$vaToast.init({
          message: 'Identifier assignment deleted!',
          iconClass: 'fa-star-o',
          position: 'bottom-right',
          duration: 6000,
          fullWidth: false,
          color: 'primary',
        })

        this.remove_id = null
        this.remove_name = null
        this.removeModalShow = false

        this.loadData()
      } catch (error) {
        this.$router.push({ name: 'login', query: { redirect: `/pages/identifiers/${this.id}/manage` } })
      }
    }
  },
}
</script>

<style lang="scss">
  .markup-tables {
    .table-wrapper {
      overflow: auto;
    }

    .va-table {
      width: 100%;
    }
  }

  .addLink {
    color: #154ec1;
  }
  .addLink:hover {
    cursor: pointer;
    text-decoration: underline;
  }
</style>
